<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card class="ma-5 pa-14" elevation="0">
          <v-row>
            <v-col cols="2">
              <v-img :src="profile.merchant_logo_url"> </v-img>
              <div class="fileUpload">
                <span class="custom-para">Upload Logo</span>
                <input
                  type="file"
                  class="upload"
                  ref="profile_picture"
                  v-on:change="handleFileUpload"
                />
              </div>
            </v-col>
            <v-col></v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div>Company Name : {{ profile.seller_name }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div>Phone : {{ profile.phone_number }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div>Email : {{ profile.email }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-textarea
                filled
                name="input-7-4"
                label="Filled textarea"
                v-model="profile.address"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-btn block color="#002441" @click="editProfile"
                >Save Changes</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" outlined centered>
      <div class="black--text">{{ text }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    snackbar: false,
    text: "",
    profile: {},
    form_data: new FormData(),
    user: {},
  }),

  methods: {
    getProfile() {
      // Make API CALL
      axios
        .get(`user/show_merchant_details/${this.user.user_id}/`)
        .then((response) => {
          // console.log({ response });
          if (response.data.success) {
            this.profile = response.data.data;
          }
        })
        .catch((err) => {
          // Handle error
        });
    },

    // Validate file extension
    validateFile(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name) === false) return false;
      return true;
    },

    // Handle Image upload
    handleFileUpload() {
      let file_data = this.$refs.profile_picture.files[0];
      // Validate file if it is an image
      let is_valid_file = this.validateFile(file_data);

      if (is_valid_file) {
        this.form_data.append("merchant_logo", file_data);
        this.profile.merchant_logo_url = URL.createObjectURL(file_data);
        // Make API CALL
      } else {
        this.createSnackbar("Please,upload an image.");
      }
    },

    editProfile() {
      this.form_data.append("address", this.profile.address);

      // Make API call to update profile
      axios
        .post(
          `merchant/update_merchant_profile/${this.user.user_id}/`,
          this.form_data
        )
        .then((response) => {
          // console.log({ response });
          if (response.data.success) {
            this.profile = response.data.data;
            this.createSnackbar("Profile updated successfully");
          } else {
            this.createSnackbar("Something went wrong");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
        });
    },

    createSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
  },

  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user && this.user.user_id) {
      this.getProfile();
    }
  },
};
</script>


<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  display: block;
  background-color: #f25f3a;
  height: 34px;
  width: 100%;
  line-height: 2.6;
  text-align: center;
  margin-top: 15px;
  /* width: 300px; */
}

.custom-para {
  font-weight: 700;
  font-size: 15px;
  color: #fff;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  text-align: center;
}
</style>